import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../AmelieUI';

import './AmelieLandingContainer.scss';

import { LANDING_PAGE_THEMES } from '../../constants';
import Utils from '../../utils/utils';

function AmelieLandingContainer({ children, className, title, containerType }) {
  const [loading, setLoading] = useState(true);
  setTimeout(() => setLoading(false), 100);

  if (loading) {
    return null;
  }

  const homeEvaluationLandingPageTheme = Utils.getDefaultConfig('homeEvaluationLandingPageTheme') || 'neighbourhood';
  const homeFinderLandingPageTheme = Utils.getDefaultConfig('homeFinderLandingPageTheme') || 'kitchen';
  const landingTheme = containerType === 'homeEvaluation' ? homeEvaluationLandingPageTheme : homeFinderLandingPageTheme;
  const officeName = Utils.getDefaultConfig('officeName');
  const officeAddress = Utils.getDefaultConfig('officeAddress');
  const avatarImage = Utils.getDefaultConfig('avatarImage');
  const officeLogo = Utils.getDefaultConfig('officeLogo');
  const phoneNumber = Utils.getDefaultConfig('phoneNumber');
  const clientName = Utils.getDefaultConfig('clientName');

  return (
    <div className="amelie-landing-container" style={{ backgroundImage: `url(${LANDING_PAGE_THEMES[landingTheme]})` }}>
      <div className="amelie-landing-container-scroll">
        <div className="amelie-landing-container-inner">
          <div className="amelie-landing-container-header">
            <div className="amelie-landing-container-header-home">
              <Icon type="home" />
              {title}
            </div>
            <div className="amelie-landing-container-header-profile">
              {clientName && avatarImage && (
                <>
                  <div
                    className="amelie-landing-container-header-avatar"
                    style={{ backgroundImage: `url(${avatarImage})` }}
                  />
                  <h1 className="amelie-landing-container-header-avatar-name">
                    {clientName}
                  </h1>
                </>
              )}
            </div>
            <div className="amelie-landing-container-header-phone">
              {(typeof phoneNumber === 'string') && (
                <>
                  <Icon type="phone" />
                  <a className="amelie-landing-container-header-phone-link" href={`tel:+1-${phoneNumber}`}>
                    {phoneNumber}
                  </a>
                </>
              )}
            </div>
          </div>
          <div className={classNames('amelie-landing-container-body', className)}>
            {children}
          </div>
          <div className="amelie-landing-container-footer">
            {officeName && officeLogo && (
              <>
                <img src={officeLogo} className="amelie-landing-container-footer-logo" alt="logo" />
                <div>
                  {officeName}
                </div>
                <div>
                  {officeAddress}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

AmelieLandingContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

AmelieLandingContainer.defaultProps = {
  className: null,
};

export default AmelieLandingContainer;
